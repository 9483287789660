import {apiSequenceMacroSteps} from '../../../../models/Api/apiSequenceMacroSteps'

function merge(settings: any, macroStep: apiSequenceMacroSteps) {

    console.log(settings)

    Object.keys(settings).forEach((key) => {
        switch (key) {
            case 'initialConcentration': {
                macroStep.SequenceSteps[2].ActionRValue = settings.initialConcentration
                break;
            }

            case 'initialConcentVolMode': {
                if (settings.initialConcentVolMode) {
                    macroStep.SequenceSteps[3].ActionIModType = 0
                    macroStep.SequenceSteps[3].ActionIModId = 0
                    macroStep.SequenceSteps[3].ActionICode = 0
                    macroStep.SequenceSteps[3].ActionRValue = 0
                    macroStep.SequenceSteps[4].ActionIModType = 200
                    macroStep.SequenceSteps[4].ActionIModId = 1
                    macroStep.SequenceSteps[4].ActionICode = 19
                    macroStep.SequenceSteps[4].ActionRValue = 1 // Mode with weight = 1
                } else {
                    macroStep.SequenceSteps[3].ActionIModType = 200
                    macroStep.SequenceSteps[3].ActionIModId = 1
                    macroStep.SequenceSteps[3].ActionICode = 18
                    macroStep.SequenceSteps[3].ActionRValue = settings.initialConcentVolume
                    macroStep.SequenceSteps[4].ActionIModType = 200
                    macroStep.SequenceSteps[4].ActionIModId = 1
                    macroStep.SequenceSteps[4].ActionICode = 19
                    macroStep.SequenceSteps[4].ActionRValue = 0 // Mode without weight = 0
                }
                break
            }

            case 'EndCriteriaDropdownSelected': {
                switch (settings.EndCriteriaDropdownSelected.id) {
                    case '1': {
                        // Time
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 1002
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 0
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
                        macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
                        macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.timeCriteria
                        break
                    }
                    case '2': {
                        // Volumic concentration factor
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 200
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
                        macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
                        macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
                        macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.volumicConcentrationFactor
                        break
                    }
                }
                break
            }
        }
    })
}

export default merge