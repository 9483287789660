import { apiSequenceMacroSteps } from '../../../../models/Api/apiSequenceMacroSteps'

export default (settings: any, macroStep: apiSequenceMacroSteps) => {
  Object.keys(settings).forEach((key) => {
    switch (key) {
      case 'EndCriteriaDropdownSelected': {
        switch (settings.EndCriteriaDropdownSelected.id) {
          case '1': {
            // Time
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 1002
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.timeCriteria
            break
          }
          case '2': {
            // Weight sum threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 9
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.weightSumThreshold
            break
          }
        }
        break
      }
    }
  })
}